.svg-turkiye-haritasi {
  max-width: 640px;
  margin: 0 auto;
  text-align: center;
}
.svg-turkiye-haritasi svg {
  width: 100%;
  height: auto;
}
.il-isimleri {
  position: absolute;
  z-index: 2;
}
.il-isimleri div {
  font-family: "Open Sans";
  display: inline-block;
  background: #000000;
  color: rgb(255, 255, 255);
  padding: 8px 16px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
#svg-turkiye-haritasi path {
  cursor: pointer;
  fill: #bcbebb;
}
#svg-turkiye-haritasi path:hover {
  fill: #9ce26e;
}
#guney-kibris {
  pointer-events: none;
}
