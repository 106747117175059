body {
  margin: 0;
  background-color: #f2f3f8;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.svg-turkiye-haritasi {
  max-width: 1140px;
  margin: 0 auto;
  text-align: center;
}
.svg-turkiye-haritasi svg {
  width: 100%;
  height: auto;
}
.il-isimleri {
  position: absolute;
  z-index: 2;
}
.il-isimleri div {
  font-family: "Open Sans";
  display: inline-block;
  background: #ffffff;
  color: #000;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 8px 16px;
  border-radius: 4px;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
}
#svg-turkiye-haritasi path {
  cursor: pointer;
  fill: #cac9c9;
}
#svg-turkiye-haritasi path:hover {
  fill: #99d659;
}
#guney-kibris {
  pointer-events: none;
}

.svg-turkiye-haritasi {
  max-width: 640px;
  margin: 0 auto;
  text-align: center;
}
.svg-turkiye-haritasi svg {
  width: 100%;
  height: auto;
}
.il-isimleri {
  position: absolute;
  z-index: 2;
}
.il-isimleri div {
  font-family: "Open Sans";
  display: inline-block;
  background: #000000;
  color: rgb(255, 255, 255);
  padding: 8px 16px;
  border-radius: 4px;
}
#svg-turkiye-haritasi path {
  cursor: pointer;
  fill: #bcbebb;
}
#svg-turkiye-haritasi path:hover {
  fill: #9ce26e;
}
#guney-kibris {
  pointer-events: none;
}

